<style lang="scss" scoped>
.contract {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;

    .header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;

        .filter-container {
            flex-grow: 1;
            display: flex;

            .title {
                font-size: 24px;
                font-weight: bold;
            }

            .el-input-item {
                display: flex;
                align-items: center;
                flex-wrap: nowrap;
                margin-right: 12px;

                .titles {
                    white-space: nowrap;
                    font-size: 14px;
                }
            }
        }
    }

    .contract-main {
        flex-grow: 1;
        overflow: hidden;
    }

    .pagation {
        padding-top: 24px;
        text-align: right;
    }
}
</style>

<style>
.drawer-body {
    padding-left: 24px;
    padding-right: 24px;
}

.demo-drawer__footer {
    padding-left: 24px;
    padding-right: 24px;
    text-align: right;
}
</style>

<template>
    <div class="contract">
        <div class="header">
            <div class="filter-container">

                <div class="el-input-item">
                    <div class="titles">名称：</div>
                    <el-input v-model="filterData.name" clearable></el-input>
                </div>
                <div class="el-input-item">
                    <div class="titles">类型：</div>
                    <!-- <el-select  v-model="filterData.type" filterable>
                        <el-option v-for="item of CUSTOMER_TYPES" :value="item.type" :key="item.name"
                            :label="item.name"></el-option>
                    </el-select> -->
                        <el-radio-group v-model="filterData.type" @change="loadData(true)" placeholder="请选择" >
    <el-radio v-for="i of CUSTOMER_TYPES" :key="i.name" :label="i.type">{{i.name}}</el-radio>
  </el-radio-group>
                </div>


                <div class="el-input-item">
                    <el-button @click="reset">重置</el-button>
                </div>

                <div class="el-input-item">
                    <el-button type="primary" @click="loadData(true)">搜索</el-button>
                </div>


            </div>
            <div class="create-container">
                <el-button type="primary" @click="showCreateNewCustomer">创建</el-button>
            </div>

        </div>
        <div class="contract-main">
            <el-table :data="list" v-if="filterData.typeDesc == 0">
                <el-table-column prop="name" label="单位名称" width="180">
                </el-table-column>
                <el-table-column label="项目数量">
                    {{ 100011 | thounds }}
                </el-table-column>

                <el-table-column label="销售总额">
                    {{ 100011 | thounds }}
                </el-table-column>



                <el-table-column prop="address" label="已收">
                    {{ 100011 | thounds }}
                </el-table-column>

                <el-table-column prop="address" label="未收">
                    {{ 100011 | thounds }}
                </el-table-column>


                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="danger" @click="removeCustomer(scope.row)" icon="el-icon-delete"
                            circle></el-button>
                        <el-button type="primary" @click="editCustomer(scope.row)" icon="el-icon-edit" circle></el-button>
                    </template>
                </el-table-column>

            </el-table>


            <el-table :data="list" v-else>
                <el-table-column prop="name" label="单位名称" width="180">
                </el-table-column>
                <el-table-column label="项目数量">
                    {{ 100011 | thounds }}
                </el-table-column>

                <el-table-column label="采购总额">
                    {{ 100011 | thounds }}
                </el-table-column>



                <el-table-column prop="address" label="已付">
                    {{ 100011 | thounds }}
                </el-table-column>

                <el-table-column prop="address" label="未付">
                    {{ 100011 | thounds }}
                </el-table-column>


                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="danger" @click="removeCustomer(scope.row)" icon="el-icon-delete"
                            circle></el-button>
                        <el-button type="primary" @click="editCustomer(scope.row)" icon="el-icon-edit" circle></el-button>
                    </template>
                </el-table-column>

            </el-table>
        </div>
        <div class="pagation">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page.sync="filterData.pageNumber" :page-size="filterData.size" layout="sizes,total, prev, pager, next"
                :total="total">
            </el-pagination>
        </div>

        <el-drawer :visible.sync="showCreate" width="400px" :title="drawerType == 'create' ? '创建' : '编辑'">

            <div class="drawer-body">
                <el-form :model="drawerData" :rules="rules" label-position="top" ref="userForm">
                    <el-form-item prop="name" label="客户名">
                        <el-input style="width:100%" clearable v-model="drawerData.name" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item prop="type" style="width:100%" label="客户类型">
                        <el-select v-model="drawerData.type" clearable placeholder="请选择活动区域">
                            <el-option v-for="item of CUSTOMER_TYPES" :label="item.name" :key="'k' + item.type"
                                :value="item.type"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>

            <div class="demo-drawer__footer">
                <el-button @click="cancelForm">取 消</el-button>
                <el-button type="primary" @click="commit">确定</el-button>
            </div>
        </el-drawer>


    </div>
</template>

<script>

import { CUSTOMER_TYPES } from "@/constant/contract"
import { customerList, addCustomer, deleteCustomer, updateCustomer } from "../../../../api/customer"
const resetData = () => {
    return {
        name: "",
        type: 0,
        typeDesc: 1,
        pageNumber: 0,
        pageSize: 10,
    }
}

const drawerData = () => {
    return {
        name: "",
        type: 0
    }
}

export default {
    data() {
        return {
            drawerType: "",
            drawerData: drawerData(),
            showCreate: false,
            CUSTOMER_TYPES,
            filterData: resetData(),
            list: [],
            total: 0,
            rules: {
                name: [{
                    required: true,
                    message: "请输入客户名"
                }],
                type: [
                    {
                        required: true,
                        message: "请选择客户类型"
                    }
                ]
            }

        }
    },
    watch: {

    },
    methods: {
        async commit() {
            this.$refs['userForm'].validate(async (valid) => {
                if (valid) {
                    this.showCreate = false

                    let httpMethod = this.drawerType == 'edit' ? updateCustomer : addCustomer
                    await httpMethod({
                        id: this.drawerData.id,
                        type: this.drawerData.type,
                        name: this.drawerData.name
                    })
                    this.$notify.success({
                        title: this.drawerType == 'create' ? "创建客户成功！" : "创建客户成功！"
                    })
                    this.loadData(true)
                }
            });
            return

        },
        cancelForm() {
            this.showCreate = false
        },
        async loadData(bool=false) {
            if(bool==true){
                this.filterData.pageNumber=1
            }
            this.filterData.typeDesc = this.filterData.type

            document.getElementsByClassName("contract-main")[0].scrollTo(0, 0)

            let { data } = await customerList({
                pageSize: this.filterData.pageSize,
                pageNumber: this.filterData.pageNumber,
                type: this.filterData.type,
                name: this.filterData.name
            })

            this.list = data.list || []
            this.total = data.total
        },
        reset() {
            let type=this.filterData.type
            this.filterData = resetData()
            this.filterData.type=type
            this.loadData()
        },
        handleSizeChange(size) {
            // this.filterData.pageSize = size
            this.filterData.pageNumber = 1
            this.loadData()
        },
        handleCurrentChange(pageNumber) {
            this.filterData.pageNumber = pageNumber
            this.loadData()
        },
        showCreateNewCustomer() {
            this.drawerType = 'create'
            this.drawerData = drawerData()
            this.showCreate = true
        },
        editCustomer(data) {
            this.drawerType = 'edit'
            this.drawerData = JSON.parse(JSON.stringify(data))
            this.showCreate = true
        },
        async removeCustomer(data) {
            this.$confirm(`确定删除客户【${data.name}】吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(async () => {
                await deleteCustomer({
                    id:data.id
                })
                this.$notify.success("删除成功！")
                this.reset()
            })
        }
    },
    components: {
    },
    mounted() {
        this.loadData()
    }
}
</script>